/** @jsx jsx */
import { css, jsx } from '@emotion/react';
// eslint-disable-next-line
import React from 'react';
import { Link } from 'gatsby';
import { tagSlug } from '../common';

export const TagLink = ({ tag }) => (
  <Link to={`/tags/${tagSlug(tag)}`} css={tagLink}>
    {tag}
  </Link>
);

const tagLink = css`
  display: inline-block;
  margin-right: 8px;
`;
