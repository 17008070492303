import React from 'react';
import { Link, graphql } from 'gatsby';
import { contentArea, postList } from '../constants';
import { TagLink } from '../components/TagLink';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const BlogIndexPage = ({ data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <Layout>
      <Seo title="Blog" />
      <div css={contentArea}>
        <h1>Blog Posts</h1>
        <ul css={postList}>
          {posts.map(({ node: page }) => (
            <li key={page.id}>
              <Link className="link" to={page.frontmatter.path}>
                {page.frontmatter.title}
              </Link>
              <div>{page.excerpt}</div>
              <div className="post-footer">
                <span className="label">{page.frontmatter.date}</span>
                &nbsp;|&nbsp;
                <span className="label">{`${page.timeToRead} min`}</span>
                &nbsp;|&nbsp;
                <span className="label">Tagged:</span>&nbsp;
                {page.frontmatter.tags.map(tag => (
                  <TagLink key={tag} tag={tag} />
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default BlogIndexPage;

export const blogIndexQuery = graphql`
  query blogIndex {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { collection: { eq: "blog" } }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          ...MdxContent
        }
      }
    }
  }
`;
